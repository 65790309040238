import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	differenceInSeconds,
	format,
	toDate,
} from 'date-fns'

export const getTimelineAttestationDateString = (
	date: string,
	longFormat = false,
) => {
	const passedDate = toDate(date)
	const dateString = format(passedDate, 'MMMM d, yyyy h:mm a')

	if (longFormat) {
		return dateString
	}

	const todayDate = toDate(new Date())
	const hourDifference = differenceInHours(todayDate, passedDate)
	if (hourDifference < 24) {
		if (hourDifference <= 0) {
			const minuteDifference = differenceInMinutes(todayDate, passedDate)
			if (minuteDifference <= 0) {
				const secondDifferences = differenceInSeconds(todayDate, passedDate)
				if (secondDifferences <= 0) {
					return `1s`
				} else {
					return `${secondDifferences}s`
				}
			} else {
				return `${minuteDifference}m`
			}
		} else {
			return `${hourDifference}h`
		}
	} else {
		return dateString
	}
}

export const getElapsedTimeString = (date: string) => {
	const passedDate = toDate(date)
	const now = toDate(new Date())

	const seconds = differenceInSeconds(now, passedDate)
	if (seconds < 60) return `${seconds} s ago`

	const minutes = differenceInMinutes(now, passedDate)
	if (minutes < 60) return `${minutes} min ago`

	const hours = differenceInHours(now, passedDate)
	if (hours < 24) return `${hours} h ago`

	const days = differenceInDays(now, passedDate)
	return `${days} d ago`
}

export const isTimestampInPastTwentyFourHours = (date: Date) => {
	const todayDate = toDate(new Date())
	if (differenceInHours(todayDate, date) > 24) {
		return true
	} else {
		return false
	}
}

export const getDifferenceFromNowInHours = (date: Date) => {
	const todayDate = toDate(new Date())
	return differenceInHours(todayDate, date)
}

export const getDifferenceFromNowInMinutes = (date: Date) => {
	const todayDate = toDate(new Date())
	return differenceInMinutes(todayDate, date)
}

export const getDateString = (date: Date) => {
	return format(date, 'MMM dd, yyy')
}

// Used for elapsed time in highlights
export const getMiniTimeDifferenceSinceDate = (date: Date) => {
	const now = toDate(new Date())
	const diffInSeconds = differenceInSeconds(now, date)

	if (diffInSeconds < 60) return `${diffInSeconds}s`
	if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`
	if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`
	if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`
	if (diffInSeconds < 2592000) return `${Math.floor(diffInSeconds / 604800)}w`
	if (diffInSeconds < 31536000) return `${Math.floor(diffInSeconds / 2592000)}mo`
	return `${Math.floor(diffInSeconds / 31536000)}y`
}

// Used for formatting highlighted cards eg: "Jan 31, 2024"
export function formatDateToLongFormat(dateString: string | Date) {
	const date = new Date(dateString)
	const options = { year: 'numeric', month: 'short', day: 'numeric' }
	return new Intl.DateTimeFormat('en-US', options).format(date)
}